import AceEditor from "react-ace";

// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-javascript'
// there are many themes to import, I liked monokai.
import 'ace-builds/src-noconflict/theme-one_dark'
// this is an optional import just improved the interaction.
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import {useState} from "react";
import {deployComponentSource} from "../../../Utils";
import {Box, Button, Divider} from "@mui/material";

export default function ({initialCode, address, caller}) {

    if (typeof initialCode != "string") {
        initialCode = ""
    }

    const [source, setSource] = useState(initialCode);

    function deploy() {
        deployComponentSource(caller, address, source)
    }

    return <Box>
        <AceEditor
            style={{
                width: '100%',
            }}
            mode="javascript"
            theme="one_dark"
            minLines={10}
            fontSize={14}
            enableLiveAutocompletion={true}
            value={source}
            showPrintMargin={false}
            onChange={s => setSource(s)}
            name="basic-code-editor"
            editorProps={{$blockScrolling: true}}
        />
        <Divider/>
        <Button onClick={deploy}>Deploy Changes</Button>
    </Box>
}