import {Alert, Box, Button, Chip, Grid, Paper, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import Component from "./Component";
import {callMethod, deployComponentSource, getComponents, nameToAddress, sleep, stateChangedCallback} from "../../Utils";


export default function ({name, caller}) {

    let address = nameToAddress(name)
    let [componentNames, setComponentNames] = useState([])
    let [iteration, setIteration] = useState(-1)
    let [loading, setLoading] = useState(true)

    useEffect(() => {
        let fetchNew = async function () {
            let result = await getComponents(address)
            result[0].sort()
            setLoading(false)
            if (result[1] > iteration) {
                setIteration(result[1])
                setComponentNames(result[0])
            }
        }

        const interval = setInterval(fetchNew, 1000)

        stateChangedCallback.push(fetchNew);

        return () => {
            clearInterval(interval)
            var index = stateChangedCallback.indexOf(fetchNew);
            if (index > -1) {
                stateChangedCallback.splice(index, 1);
            }
        }
    })

    const componentElements = componentNames.map((name) => {
        return <Component key={name} address={address} caller={caller} name={name} iteration={iteration}/>
    })

    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return <Paper sx={{ p: 2, m: 1, alignItems: 'center'}}>
        <h2>{name}</h2>
        <Box sx={{pb: 2}}>
            <Chip label={"Iteration " + iteration}/><Chip label={"Address " + address}/>
        </Box>
        {componentElements.length > 0 ? <h4>Components</h4> : null}
        {componentElements}
        <h4>Actions</h4>
        <AddComponent address={address} caller={caller}/>
    </Paper>

}

function AddComponent({address, caller}) {
    const [component, setComponent] = useState("")
    const [error, setError] = useState()
    const [success, setSuccess] = useState()

    async function addComponent() {
        try {
            await callMethod(caller, address, component, "new")
            setSuccess("Update complete")
            setError()
            await sleep(1000)
            setSuccess()
            setComponent()
        } catch (e) {
            setError(e)
            setSuccess()
            await sleep(5000)
            setError()
        }
    }

    let alert;
    if (error) {
        alert = <Alert severity="error">{error}</Alert>
    } else if (success) {
        alert = <Alert severity="success">{success}</Alert>
    }

    return <Grid container  sx={{mt:1}}>

        <Grid item alignItems="stretch" style={{ display: "flex" }} >
            <Button onClick={addComponent} color="primary" variant="outlined">
                Add Component
            </Button>
        </Grid>

        <Grid item>
            <TextField
                value={component}
                sx={{ml:2}}
                label="Component Name"
                size="small"
                variant="outlined"
                onChange={(event) => setComponent(event.target.value)}/>
        </Grid>

        <Grid item xs={12}  sx={{mt:1}}>
            {alert}
        </Grid>

    </Grid>
}
