import React from "react";
import {Box, CircularProgress} from "@mui/material";

export default function Loading() {
  return (
      <Box sx={{  margin: 'auto', width: 'inherit', position: 'absolute', alignItems: 'center', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', height: 'inherit'}}>
          <CircularProgress/>
      </Box>
  );
}

