import {Checkbox, TextField} from "@mui/material";
import {nameToAddress} from "../../../Utils";


export function defaultArgValues() {
    return {
        number: 0,
        string: "",
        address: nameToAddress("default"),
        bool: false,
        ref: {
            "Name": "client",
            "Address": nameToAddress("default")
        }
    }
}

export default function ({name, type, setValue: callback}) {

    if (type === "number") {
        return <TextField size="small" type="number" onChange={(event) => callback(Number(event.target.value))}
                          placeholder={name} inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}/>
    }
    if (type === "string") {
        return <TextField size="small" onChange={(event) => callback(event.target.value)} placeholder={name}/>
    }
    if (type === "address") {
        return <TextField size="small" onChange={(event) => callback(nameToAddress(event.target.value))}
                          placeholder={name}/>
    }
    if (type === "bool") {
        return <Checkbox onChange={(event) => callback(event.target.checked)}/>
    }
    if (type === "ref") {
        return <TextField size="small" placeholder={name}
                          onChange={(event) => {
                              const split = event.target.value.split("+")
                              callback({
                                  Name: split[0],
                                  Address: nameToAddress(split[1])
                              })
                          }}/>
    }
}

