import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography} from "@mui/material";
import {callConstMethod, getComponents} from "../../Utils";
import {useEffect, useState} from "react";
import ScriptEditor from "../entity/table/ScriptEditor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Method from "../entity/table/Method";
import Balance from "../entity/table/Balance";

export default function ({address, name, caller}) {

    let [iteration, setIteration] = useState(-100)


    let [details, setDetails] = useState({
        description: "loading",
        actions: []
    })

    useEffect(() => {
        {
            let func = async function () {
                let result = await getComponents(address)
                let newIteration = result[1]
                if (newIteration > iteration) {
                    let result = await callConstMethod(caller, address, name, "details")
                    setIteration(newIteration)
                    setDetails(result)
                }
            }
            func()
        }
    })

    let methodComponents = [];
    if(details.methods) {
        for(let i in details.methods) {
            const methodName = i
            const args = details.methods[i]
            methodComponents.push(<Method key={methodName} caller={caller} isConst={false} isPayable={false}  address={address} componentName={name} methodName={methodName} args={args} />)
        }
    }

    let payableMethodComponents = [];
    if(details.payableMethods) {
        for(let i in details.payableMethods) {
            const methodName = i
            const args = details.payableMethods[i]
            payableMethodComponents.push(<Method key={methodName} caller={caller} isConst={false} isPayable={true} address={address} componentName={name} methodName={methodName} args={args} />)
        }
    }

    let constMethodComponents = [];
    if(details.constMethods) {
        for(let i in details.constMethods) {
            const methodName = i
            const args = details.constMethods[i]
            constMethodComponents.push(<Method key={methodName} caller={caller} isConst={true} isPayable={false} address={address} componentName={name} methodName={methodName} args={args} />)
        }
    }

    var element;

    if (name === "script" && details.state !== undefined) {
        element = <ScriptEditor initialCode={details.state} address={address} caller={caller}/>
    }

    return <Accordion elevation={3}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography sx={{width: '33%', flexShrink: 0}}>
                {name}
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
                {details.description}
            </Typography>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails>
            {methodComponents.length > 0 ? <h4>Methods</h4> : null}
            {methodComponents}
            {payableMethodComponents.length > 0 ? <h4>Payable Methods</h4> : null}
            {payableMethodComponents}
            {constMethodComponents.length > 0 ? <h4>Const Methods</h4> : null}
            {constMethodComponents}
            {details.balance ? <Balance balance={details.balance}/> : null}
            {element}
        </AccordionDetails>
    </Accordion>
}



