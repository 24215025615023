import {Box, Button, Paper, TextField} from "@mui/material";
import {useState} from "react";
import {callMethod, nameToAddress} from "../Utils";

export default function ChooseUserName({publicKey}) {

    let [userName, setUserName] = useState("")
    let [error, setError] = useState()

    async function submitUserName() {
        try {
            await callMethod(publicKey, nameToAddress(publicKey), "client", "new", userName)
            await callMethod(publicKey, nameToAddress(publicKey), "account", "new", userName)
        } catch (e) {
            console.log("Got an error: ", e)
        }
    }

    return <Paper sx={{m:1, p:1}}>
        <h2>Welcome to M2! To finish setting up your account, please choose a username...</h2>
        <Box>
            <TextField size="small" value={userName} onChange={(event) => setUserName(event.target.value)} placeholder={"Username"}/>
        </Box>
        <Box>
            <Button onClick={submitUserName} variant={"outlined"}>Select</Button>
        </Box>
        <p>{error}</p>
    </Paper>
}