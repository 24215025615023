import React, {useEffect, useState, useCallback} from "react";
import {Route, Switch, useHistory, useParams} from "react-router";
import {magic} from "../magic";

import RestoreIcon from "@mui/icons-material/Restore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {Link} from 'react-router-dom';

import Loading from "./Loading";
import {ethers} from "ethers";
import {BottomNavigation, BottomNavigationAction, Box, Button, Paper} from "@mui/material";
import Login from "./Login";
import Entity from "./explorer/Entity";
import Friends from "./Friends";
import {callConstMethod, callMethod, getEntity, nameToAddress, useEntity} from "../Utils";
import ChooseUserName from "./ChooseUserName";
import Balance from "./entity/table/Balance";
import ThreeEntity from "./entity/ThreeEntity";
import JsonEntity from "./entity/JsonEntity";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import TableEntity from "./entity/table/TableEntity";

export const provider = new ethers.providers.Web3Provider(magic.rpcProvider);

export default function Profile() {
    const [userMetadata, setUserMetadata] = useState();
    const [publicKey, setPublicKey] = useState();
    const history = useHistory();

    // async function confirmFriend(friendAddress) {
    //     console.log("confirming ", friendAddress)
    //     try {
    //         await callMethod(publicKey, nameToAddress(publicKey), "account", "confirmAddFriend", friendAddress)
    //     } catch (e) {
    //         console.log("Got an error: ", e)
    //     }
    // }
    //


    useEffect(() => {
        // On mount, we check if a user is logged in.
        // If so, we'll retrieve the authenticated user's profile.

        (async () => {
            const isLoggedIn = await magic.user.isLoggedIn()
            if (isLoggedIn) {
                let metaData = await magic.user.getMetadata()
                setUserMetadata(metaData)
                let accounts = await provider.listAccounts()
                console.log("public key", accounts[0])
                setPublicKey(accounts[0])
            } else {
                history.push("/login");
            }

        })()
    }, []);


    if(publicKey) {
        return <Box><Switch>
            <Route path="/home" exact>
                <Login/>
            </Route>

            <Route path="/debug" exact>
                <Entity key={"barry"} caller={publicKey} name={publicKey}/>
            </Route>

            <Route path="*">
                <AccountPage publicKey={publicKey} userMetadata={userMetadata}/>
            </Route>
        </Switch>
        </Box>
    } else {
        return <Loading/>
    }
}

function AccountPage({publicKey, userMetadata}) {
    const [components, iteration, loading, error] = useEntity(publicKey, nameToAddress(publicKey))

    const history = useHistory();

    const logout = useCallback(() => {
        magic.user.logout().then(() => {
            history.push("/login");
        })
    }, [history]);


    if(loading) {
        return <Loading/>
    } else {
        if(error) {
            return <p>Error {error}</p>
        } else {

            const account = components["account"]


            if(account) {

                let balanceElement = components["client"] ? <Balance balance={components["client"].balance}/> : null

                return <Box sx={{p: 5}}>
                    <Paper sx={{p:5}}>
                        <p>Email: {userMetadata.email}</p>
                        <p>Public Key: {publicKey}</p>
                        <p>Username: {account.userName}</p>
                        <Button variant={"outlined"} onClick={logout} >Logout</Button>
                        {balanceElement}
                    </Paper>
                </Box>
            }
            else {
                return <ChooseUserName publicKey={publicKey} />
            }
        }
    }
}
