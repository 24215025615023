import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Balance from "./Balance";
import Argument, {defaultArgValues} from "./Argument";
import {useState} from "react";
import {callConstMethod, callMethod, callPayableMethod, nameToAddress, sleep} from "../../../Utils";
import {Alert, Box} from "@mui/material";

export default function Action({publicKey, address, componentName, action}) {
    const [open, setOpen] = React.useState(false);
    const [argValues, setArgValues] = useState({})
    const [error, setError] = useState()
    const [success, setSuccess] = useState()

    let alert;
    if (error) {
        alert = <Alert severity="error">{error}</Alert>
    } else if (success) {
        alert = <Alert severity="success">{success}</Alert>
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSuccess()
        setError()
    };

    let descriptionElement;
    if (action.description) {
        descriptionElement = <DialogContentText>
            {action.description}
        </DialogContentText>
    }

    let costElement;
    if (action.cost) {
        costElement = <Balance balance={action.cost} title={"Cost"}/>
    }

    function setArgValue(argName, argValue) {
        let newValue = {}
        newValue[argName] = argValue
        setArgValues({...argValues, ...newValue})
        argValues[argName] = argValue
    }

    let argElements = [];
    if (action.args) {
        argElements = action.args.map(arg => {
            const name = arg[0]
            const type = arg[1]
            return <Box key={name} sx={{p: 1}}>
                <Argument name={name} type={type} setValue={(value) => setArgValue(name, value)}/>
            </Box>
        })
    }

    let emoji = "";
    if (action.cost) {
        emoji = " 💰"
    }


    async function finalizeAction() {

        try {
            let valueMap = {}

            if(action.args) {
                action.args.map((arg) => {
                    const name = arg[0]
                    const type = arg[1]
                    valueMap[name] = argValues[name] ? argValues[name] : defaultArgValues()[type]
                })
            }

            const args = action.method.slice(1);
            const transformedArgs = args.map(arg => {
                if (typeof arg === 'string' && arg.indexOf("$") === 0) {
                    const argName = arg.slice(1)
                    return valueMap[argName]
                }
                return arg
            })

            console.log(transformedArgs)
            console.log(action.method)


            let result
            if (action.cost) {
                result = await callPayableMethod(publicKey, address, componentName, action.method[0], action.cost, ...transformedArgs)
            } else {
                console.log(publicKey, address, componentName, action.method[0], transformedArgs)
                result = await callMethod(publicKey, address, componentName, action.method[0], ...transformedArgs)
            }

            setSuccess(result === null ? "Success!" : JSON.stringify(result))
            await sleep(15000)
            handleClose()
            setSuccess()
            setError()

        } catch (e) {
            console.log(e)
            setError(JSON.stringify(e))
            setSuccess()
            await sleep(15000)
            handleClose()
            setSuccess()
            setError()
        }
    }

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                {action.name + emoji}
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{action.name}</DialogTitle>
                <DialogContent>
                    {descriptionElement}
                    {costElement}
                    {argElements.length > 0 ? <h4>Details</h4> : null}
                    {argElements}
                    <Box sx={{pt: 1}}>{alert} </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={finalizeAction}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}