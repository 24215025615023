import {useEffect, useState} from "react";
import {clear} from "@testing-library/user-event/dist/clear";

export let stateChangedCallback = []

export let sleep = function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export let callMethod = function (caller, address, name, method, ...args) {
    let promise = window.callPayableMethodPromise(caller, {
        "Name": name,
        "Address": address
    }, method, {}, ...args)

    promise.then(result => stateChangedCallback.forEach(callback => callback()))

    return promise
}

export let getEntity = async function (caller, address) {
    if(caller === undefined || address === undefined) {
        throw TypeError("no valid caller and address");
    }

    const componentInfo = await getComponents(address)
    const components = componentInfo[0]
    const iteration = componentInfo[1]
    let entity = {
        iteration: iteration,
        components: {}
    }
    for (let i in components) {
        const component = components[i]
        const details = await callConstMethod(caller, address, component, "details")
        entity.components[component] = details
    }
    return entity
}

export let callPayableMethod = function (caller, address, name, method, balance, ...args) {
    let promise = window.callPayableMethodPromise(caller, {
        "Name": name,
        "Address": address
    }, method, balance, ...args)

    promise.then(result => stateChangedCallback.forEach(callback => callback()))

    return promise
}

export let callConstMethod = function (caller, address, name, method, ...args) {
    return window.callConstMethodPromise(caller, {
        "Name": name,
        "Address": address
    }, method, ...args)
}

export let nameToAddress = function (name) {
    return window.nameToAddress(name)
}

export let getComponents = function (address) {
    return window.getComponentsPromise(address)
}

export let deployComponentSource = async function (caller, address, source) {
    return callMethod(caller, address, "script", "new", source)
}

export let useEntityOnce = function (caller, address) {
    let [loading, setLoading] = useState(true)
    let [iteration, setIteration] = useState()
    let [components, setComponents] = useState()
    let [error, setError] = useState()

    useEffect(() => {
        getEntity(caller, address).then(entity => {
            setIteration(entity.iteration)
            setComponents(entity.components)
            setLoading(false)
        }).catch(e => {
            setError(e)
            setLoading(false)
        })
    });

    return [components, iteration, loading, error]
}

export let useEntity = function (caller, address) {
    let [loading, setLoading] = useState(true)
    let [iteration, setIteration] = useState()
    let [components, setComponents] = useState()
    let [error, setError] = useState()
    let [savedAddress, setSavedAddress] = useState()


    useEffect(() => {
        function getLatest() {
            getEntity(caller, address).then(entity => {
                if(iteration === undefined || entity.iteration > iteration || savedAddress !== address) {
                    setIteration(entity.iteration)
                    setComponents(entity.components)
                    setSavedAddress(address)
                    setLoading(false)
                }
            }).catch(e => {
                setError(e)
                setComponents()
                setIteration()
                setSavedAddress(address)
                setLoading(false)
            })
        }

        if(savedAddress !== address) {
            setError()
            setComponents()
            setIteration()
            setSavedAddress(address)
            setLoading(true)
            getLatest()
        }

        const handle = setInterval(getLatest, 2000)
        return () => clearInterval(handle)
    });

    return [components, iteration, loading, error]
}