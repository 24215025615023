import {useHistory, useParams, useLocation} from "react-router";
import {nameToAddress, useEntity} from "../../Utils";
import React, {useEffect, useState} from "react";
import Loading from "../Loading";
import {Box} from "@mui/material";
import ThreeEntity from "./ThreeEntity";
import TableEntity from "./table/TableEntity";
import JsonEntity from "./JsonEntity";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import GraphEntity from "./GraphEntity";


export default function Entity() {
    const { name } = useParams()
    const history = useHistory()
    const [mode, setMode] = useQueryParam('mode', StringParam)
    const [caller, setCaller] = useQueryParam('caller', StringParam)
    const [components, iteration, loading, error] = useEntity(caller || "unknown", nameToAddress(name))

    useEffect(() => {
        if(!mode || (mode !== "3d" && mode !== "table" && mode !== "json" && mode !== "graph")) {
            setMode("3d")
        }

        if(!caller) {
            setCaller("garry")
        }
    })

    if(loading || !caller || !mode) {
        return <Loading/>
    } else {
        if(error) {
            return <p>Error {error}</p>
        } else {
            if(mode === "3d") {
                return <Box>
                    <ThreeEntity components={components} publicKey={caller} name={name} iteration={iteration} history={history} mode={mode}/>
                    <ToggleModes value={mode} callback={setMode}/>
                </Box>
            }
            else if(mode === "table") {
                return <Box>
                    <TableEntity name={name} iteration={iteration} publicKey={caller} components={components} caller={caller} />
                    <ToggleModes value={mode} callback={setMode}/>
                </Box>
            }
            else if(mode === "graph") {
                return <Box>
                    <GraphEntity name={name} components={components} history={history} caller={caller}/>
                    <ToggleModes value={mode} callback={setMode} />
                </Box>
            }
            else if(mode === "json") {
                return <Box>
                    <JsonEntity components={components}/>
                    <ToggleModes value={mode} callback={setMode}/>
                </Box>
            }
        }
    }
}

function ToggleModes({value, callback}) {

    const handleAlignment = (event, newValue) => {
        if(newValue !== null) {
            callback(newValue)
        }
    };

    return (
        <Box sx={{position: 'absolute', top: 10, right: 10}}>
            <ToggleButtonGroup
                value={value}
                exclusive
                size={"small"}
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                <ToggleButton value="3d" aria-label="left aligned">
                    3D
                </ToggleButton>
                <ToggleButton value="table" aria-label="centered">
                    Table
                </ToggleButton>
                <ToggleButton value="graph" aria-label="right aligned">
                    Graph
                </ToggleButton>
                <ToggleButton value="json" aria-label="justified">
                    JSON
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
}