import {AppBar, Box, Button, createTheme, InputBase, styled, TextField, Toolbar, Typography} from "@mui/material";
import Entity from "./Entity";
import {callMethod, deployComponent, nameToAddress} from "../../Utils";
import {ThemeProvider} from "@emotion/react";

import {useState} from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import * as React from "react";

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


export default function Explorer() {

    let [name, setName] = useState("")
    let [entities, setEntities] = useState([])
    let [callerName, setCallerName] = useState("garry")
    function handleSearch(event) {
        if (event.key == "Enter") {
            event.preventDefault()
            console.log(name)
            setEntities(entities.concat([name]))
            setName("")
        }
    }

    let entityComponents = entities.map(entity => <Entity key={entity} name={entity} caller={callerName}/>)



    return (
        <Box className="App">
                <AppBar position="static">
                    <Toolbar>
                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    edge="start"*/}
                        {/*    color="inherit"*/}
                        {/*    aria-label="menu"*/}
                        {/*    sx={{mr: 2}}*/}
                        {/*>*/}
                        {/*    <MenuIcon/>*/}
                        {/*</IconButton>*/}
                        <Typography variant="h6" component="div">
                            M2 Entity Search
                        </Typography>
                        <Box sx={{p: 1, pl: 3}}>
                            <TextField
                                component="div"
                                sx={{flexGrow: 1, fontSize: '10'}}
                                value={name}
                                label="Search Entity Name"
                                size="small"
                                variant="filled"
                                onKeyPress={handleSearch}
                                onChange={(event) => setName(event.target.value)}/>
                        </Box>
                        <Box sx={{p: 1, pl: 3, flexGrow: 1}}>
                            <Button color="inherit" variant="outlined" onClick={() => setEntities([])}>Clear
                                Results</Button>
                        </Box>
                        <Box sx={{p: 1, pl: 3}}>
                            <TextField
                                component="div"
                                sx={{flexGrow: 1, fontSize: '10'}}
                                value={callerName}
                                label="Caller Name"
                                size="small"
                                variant="filled"
                                onKeyPress={handleSearch}
                                onChange={(event) => setCallerName(event.target.value)}/>
                        </Box>
                        <Box sx={{p: 1, pl: 3}}>
                            <ToggleModes/>
                        </Box>
                        {/*<Button color="inherit" onClick={bootstrap}>Bootstrap</Button>*/}
                        {/*<Button color="inherit" onClick={() => setEntities([])}>Clear Search</Button>*/}
                    </Toolbar>
                </AppBar>
                <Box sx={{width: '100%', maxWidth: 800, alignItems: 'center', margin: 'auto'}}>
                    {entityComponents}
                </Box>
        </Box>
    );
}


function ToggleModes({value, callback}) {

    const handleAlignment = (event, newValue) => {
        callback(newValue)
    };

    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            // size={"small"}
            onChange={handleAlignment}
            aria-label="text alignment"
        >
            <ToggleButton value="3d" aria-label="left aligned">
                3D
            </ToggleButton>
            <ToggleButton value="table" aria-label="centered">
                Table
            </ToggleButton>
            {/*<ToggleButton value="graph" aria-label="right aligned">*/}
            {/*    Graph*/}
            {/*</ToggleButton>*/}
            <ToggleButton value="json" aria-label="justified">
                JSON
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
