import { ForceGraph2D, ForceGraph3D, ForceGraphVR, ForceGraphAR } from 'react-force-graph';
import {Box, Button} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from "react";
import * as THREE from "three";
import * as SPRITE from "three-spritetext";
import {getEntity, nameToAddress} from "../../Utils";

function genRandomTree(N = 300, reverse = false) {
    return {
        nodes: [...Array(N).keys()].map(i => ({ id: i })),
        links: [...Array(N).keys()]
            .filter(id => id)
            .map(id => ({
                [reverse ? 'target' : 'source']: id,
                [reverse ? 'source' : 'target']: Math.round(Math.random() * (id-1))
            }))
    };
}

export default function GraphEntity({name, caller, components, history}) {

    const [displayWidth, setDisplayWidth] = useState(window.innerWidth);
    const [displayHeight, setDisplayHeight] = useState(window.innerHeight);

    let links = []
    let nodeMap = {}

    let visited = {}
    useEffect(() => {
        Explore(name, caller, subGraph => {

            let newNodeMap = {}
            subGraph.nodes.forEach(node => {
                newNodeMap[node.id] = node
            })

            subGraph.links.forEach(link => {
                if(nodeMap[link.source]) {
                    link.source = nodeMap[link.source]
                } else {
                    link.source = newNodeMap[link.source]
                }
                if(nodeMap[link.target]) {
                    link.target = nodeMap[link.target]
                } else {
                    link.target = newNodeMap[link.target]
                }
            })

            data.links.push(...subGraph.links)

            subGraph.nodes.forEach(node => {
                if(!nodeMap[node.id]) {
                    nodeMap[node.id] = node
                }
            })

            const graphData = {
                nodes : Object.values(nodeMap),
                links : links
            }

            setData(graphData)
        }, visited, 0, 2)

    }, [])

    const graphData = {
        nodes : Object.values(nodeMap),
        links : links
    }

    const [data, setData] = useState(graphData);

    document.body.style.overflow = "hidden";

    window.addEventListener('resize', () => {
        setDisplayWidth(window.innerWidth);
        setDisplayHeight(window.innerHeight);
    });

    return <Box sx={{overflow: 'hidden', position: 'absolute'}}>
        <ForceGraph3D
            width={displayWidth}
            height={displayHeight}
            onNodeClick={(node) => history.push({
                pathname : "/entities/" + node.id,
                search: window.location.search
            })}
            linkThreeObjectExtend={true}
            linkAutoColorBy={(link) => link.relationship}
            linkThreeObject={link => {
                // extend link with text sprite
                const sprite = new SPRITE.default(link.relationship);
                sprite.color = 'lightgrey';
                sprite.textHeight = 1.5;
                return sprite;
            }}
            linkCurvature={0.05}
            linkOpacity={0.8}
            linkWidth={0.5}
            linkPositionUpdate={(sprite, { start, end }) => {
                const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
                    [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                })));

                // Position sprite
                Object.assign(sprite.position, middlePos);
            }}
            nodeThreeObject={node => {
                return new SPRITE.default(node.id, 3, "white");
            }}
            graphData={data}/>
    </Box>
}

async function Explore(name, caller, callback, visited, depth, maxDepth) {
    if(visited[name] || depth >= maxDepth) return
    visited[name] = true


    const entity = await getEntity(caller, nameToAddress(name))
    const components = entity.components

    let links = []
    let nodeMap = {}

    for(let i in components) {
        const component = components[i]
        if (component.relationships) {
            for (let j in component.relationships) {
                const relationship = component.relationships[j]
                if (relationship.edges) {
                    for(let k in relationship.edges) {
                        const target = relationship.edges[k]
                        links.push({
                            source : name,
                            target : target,
                            relationship: relationship.name
                        })
                        nodeMap[target] = {
                            id : target,
                            color: "white"
                        }
                        nodeMap[name] = {
                            id : name,
                            color: "white"
                        }
                        if(!visited[target]) {
                            Explore(target, caller, callback, visited, depth + 1, maxDepth)
                        }
                    }
                }
            }
        }
    }

    if(links.length > 0) {
        const graphData = {
            nodes : Object.values(nodeMap),
            links : links
        }
        callback(graphData)
    }
}

