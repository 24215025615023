import React, {useState} from "react";
import {Route, Switch, useHistory, useParams} from "react-router";
import { BrowserRouter } from "react-router-dom";
import {QueryParamProvider} from 'use-query-params';

import Login from "./Login";
import Callback from "./Callback";
import Profile from "./Profile";
import Explorer from "./explorer/Explorer";
import Entity from './entity/Entity'

export default function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>

          <Route path="/callback" exact>
            <Callback />
          </Route>

          <Route path="/explorer" exact>
            <Explorer />
          </Route>

          <Route path="/entities/:name" exact>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Entity publicKey={"garry"} />
            </QueryParamProvider>
          </Route>

          <Route path="*">
            <Profile />
          </Route>
        </Switch>
    </BrowserRouter>
  );
}