import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography} from "@mui/material";
import {callConstMethod, getComponents} from "../../../Utils";
import {useEffect, useState} from "react";
import ScriptEditor from "./ScriptEditor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Method from "./Method";
import Balance from "./Balance";

export default function TableComponent({name, details, caller, address}) {


    let methodComponents = []
    if(details.methods) {
        for(let i in details.methods) {
            const methodName = i
            const args = details.methods[i]
            methodComponents.push(<Method key={methodName} caller={caller} isConst={false} isPayable={false}  address={address} componentName={name} methodName={methodName} args={args} />)
        }
    }

    let payableMethodComponents = []
    if(details.payableMethods) {
        for(let i in details.payableMethods) {
            const methodName = i
            const args = details.payableMethods[i]
            payableMethodComponents.push(<Method key={methodName} caller={caller} isConst={false} isPayable={true} address={address} componentName={name} methodName={methodName} args={args} />)
        }
    }

    let constMethodComponents = []
    if(details.constMethods) {
        for(let i in details.constMethods) {
            const methodName = i
            const args = details.constMethods[i]
            constMethodComponents.push(<Method key={methodName} caller={caller} isConst={true} isPayable={false} address={address} componentName={name} methodName={methodName} args={args} />)
        }
    }

    var element;

    if (name === "script" && details.state !== undefined) {
        element = <ScriptEditor initialCode={details.state} address={address} caller={caller}/>
    }

    return <Accordion elevation={3}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography sx={{width: '33%', flexShrink: 0}}>
                {name}
            </Typography>
            <Typography sx={{color: 'text.secondary'}}>
                {details.description}
            </Typography>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails>
            {methodComponents.length > 0 ? <h4>Methods</h4> : null}
            {methodComponents}
            {payableMethodComponents.length > 0 ? <h4>Payable Methods</h4> : null}
            {payableMethodComponents}
            {constMethodComponents.length > 0 ? <h4>Const Methods</h4> : null}
            {constMethodComponents}
            {details.balance ? <Balance balance={details.balance}/> : null}
            {element}
        </AccordionDetails>
    </Accordion>
}



