import AceEditor from "react-ace";
import {Box, Container} from "@mui/material";
import * as React from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default function JsonEntity({components}) {
    const entity = {
        components: components
    }

    document.body.style.overflow = "auto";

    const source = JSON.stringify(entity, null, 2)

    return  <SyntaxHighlighter language="json" style={darcula} wrapLongLines={true} >
        {source}
    </SyntaxHighlighter>


    return <Box sx={{  margin: 'auto', width: 'inherit', position: 'absolute', alignItems: 'center', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', height: 'inherit'}}>
        <AceEditor
        mode="javascript"
        theme="one_dark"
        fontSize={14}
        readOnly={true}
        width={"800px"}
        height={"600px"}
        value={source}
        showPrintMargin={false}
        name="json-viewer"
        editorProps={{$blockScrolling: true}}
    />
    </Box>



}