import {nameToAddress, useEntity, useEntityOnce} from "../../Utils";
import {AppBar, Box, Button, Card, Chip, Container, Paper, TextField, Toolbar, Typography} from "@mui/material";
import {Canvas, useFrame, useLoader} from '@react-three/fiber'
import {Html, OrbitControls, Sphere, useGLTF, RoundedBox} from "@react-three/drei"
import * as React from 'react';
import Action from "./table/Action";
import Loading from "../Loading";
import {useState} from "react";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Entity from "../explorer/Entity";
import AceEditor from "react-ace";

export default function ThreeEntity({components, iteration, publicKey, name, history}) {
    const address = nameToAddress(name)

    document.body.style.overflow = "hidden";

    const entity = {
        components: components
    }

    let actionElements = []
    let relationshipElements = []
    let meshes = []

    for (let i in components) {
        const component = components[i]
        const componentName = i
        if (component.actions) {
            for (let j in component.actions) {
                const action = component.actions[j]
                actionElements.push(
                    <Action key={componentName + action.method[0]} publicKey={publicKey} componentName={componentName}
                            address={address} action={action}/>
                )
            }
        }
        if (component.relationships) {
            for (let j in component.relationships) {
                const relationship = component.relationships[j]

                console.log(relationship.edges)
                if (relationship.edges) {
                    let links = relationship.edges.map(edge => <Button key={edge}
                                                                       onClick={() => history.push(edge)}>{edge}</Button>)

                    relationshipElements.push(
                        <p key={relationship.name}>
                            {relationship.name} {links}
                        </p>
                    )
                }
            }
        }
        if (component.meshes) {
            for (let j in component.meshes) {
                const mesh = component.meshes[j]
                meshes.push(mesh)
            }
        }
    }


    return <Box>
       <React.Suspense fallback={<Loading/>}>
            <View3D meshes={meshes} publicKey={publicKey} name={name} onClick={(clickedName) => {
                console.log(clickedName, name)
                if (name !== clickedName) {
                    history.push(clickedName)
                }
            }}/>
            <Box sx={{p: 3, color: 'text.primary', zIndex: 100, maxWidth: 500}} className={"noselect"}>
                <Typography variant="h2" gutterBottom>{name}</Typography>
                <Box sx={{pb: 2}}>
                    <Chip label={"Iteration " + iteration}/><Chip label={"Address " + address}/>
                </Box>
                {actionElements.length > 0 ? <h4>Actions</h4> : null}
                {actionElements}
                {relationshipElements.length > 0 ? <h4>Relationships</h4> : null}
                {relationshipElements}
            </Box>
        </React.Suspense>
    </Box>
}

function View3D({meshes, name, publicKey, onClick}) {
    console.log("NEW 3D")

    return <Box sx={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
        <Canvas sx={{position: 'absolute'}}>
            <ambientLight/>
            <directionalLight position={[10, 10, 10]}/>
            <Object name={name} publicKey={publicKey} onClick={onClick}/>
            {/*<group>*/}
            {/*    {meshElements.length > 0 ? meshElements : <RoundedBox>*/}
            {/*        <meshStandardMaterial color="#aa4444"/>*/}
            {/*    </RoundedBox>}*/}
            {/*    <RoundedBox/>*/}
            {/*</group>*/}
            <OrbitControls/>
        </Canvas>
    </Box>
}

function GLTFMesh({url, position, rotation, scale}) {
    let gltf = useGLTF(url)

    let [dialGeometry, setDialGeometry] = useState()

    if (!dialGeometry) {
        const clone = gltf.scene.clone(true)
        setDialGeometry(clone)
    }

    return <primitive object={dialGeometry} dispose={null} position={position} rotation={rotation} scale={scale}/>
}

function Object({publicKey, name, position, rotation, scale, onClick}) {

    position = position || [0, 0, 0]
    rotation = rotation || [0, 0, 0]

    scale = scale || [1, 1, 1]
    let [components, iteration, loading, error] = useEntity(publicKey, nameToAddress(name))

    if (!loading && !error) {
        const geometries = []
        let id = 0;
        for (let i in components) {
            const component = components[i]
            if (component.geometry) {
                for (let j in component.geometry) {
                    id++;
                    const geometry = component.geometry[j]
                    geometry.id = id;
                    geometries.push(geometry)
                }
            }
        }
        const meshElements = geometries.map(geom => <GenericGeometry key={geom.id.toString()} geometry={geom}
                                                                     publicKey={publicKey} onClick={onClick}/>)
        return <group position={position} rotation={rotation} scale={scale} onClick={() => onClick(name)}>
            {meshElements}
            {meshElements.length == 0 ? <RoundedBox/> : null}
        </group>
    } else {
        return <group/>
    }
}

function GenericGeometry({geometry, publicKey, onClick}) {
    const position = geometry.position || [0, 0, 0]
    const rotation = geometry.rotation || [0, 0, 0]
    const scale = geometry.scale || [1, 1, 1]
    const color = geometry.color || "#ffffff"

    if (geometry.type === "mesh") {
        return <GLTFMesh key={geometry.id} url={geometry.url} position={position} rotation={rotation} scale={scale}/>
    } else if (geometry.type === "box") {
        return <RoundedBox key={geometry.id} position={position} rotation={rotation} scale={scale}>
            <meshStandardMaterial color={color}/>
        </RoundedBox>
    } else if (geometry.type === "sphere") {
        return <Sphere key={geometry.id} position={position} rotation={rotation} scale={scale}/>
    } else if (geometry.type === "entity") {
        return <Object key={geometry.id} publicKey={publicKey} name={geometry.name} position={position}
                       rotation={rotation} scale={scale} onClick={onClick}/>
    }
}


function ToggleModes({value, callback}) {

    const handleAlignment = (event, newValue) => {
        callback(newValue)
    };

    return (
        <Box sx={{position: 'absolute', top: 10, right: 10}}>
            <ToggleButtonGroup
                value={value}
                exclusive
                size={"small"}
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                <ToggleButton value="3d" aria-label="left aligned">
                    3D
                </ToggleButton>
                <ToggleButton value="table" aria-label="centered">
                    Table
                </ToggleButton>
                {/*<ToggleButton value="graph" aria-label="right aligned">*/}
                {/*    Graph*/}
                {/*</ToggleButton>*/}
                <ToggleButton value="json" aria-label="justified">
                    JSON
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
}