import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './components/explorer/Explorer'
import App from "./components/App";
import {createTheme, InputBase, styled, ThemeProvider} from "@mui/material";

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#111111",
        }
    },
    typography: {
        // fontSize: 12,
    },
});

window.begin = function() {
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={darkTheme}>
            <App />
            </ThemeProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
