import Argument from "./Argument";
import {Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, TextField, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {callConstMethod, callMethod, callPayableMethod, nameToAddress, sleep} from "../../../Utils";
import {useState} from "react";


export default function Method({address, caller, componentName, methodName, args, isConst, isPayable}) {






    const [tokenName, setTokenName] = useState("")
    const [tokenAmount, setTokenAmount] = useState(0)

    const [error, setError] = useState()
    const [success, setSuccess] = useState()

    let alert;
    if (error) {
        alert = <Alert severity="error">{error}</Alert>
    } else if (success) {
        alert = <Alert severity="success">{success}</Alert>
    }

    const [argValues, setArgValues] = useState({})

    const defaultArgValues = {
        number: 0,
        string: "",
        address: nameToAddress("default"),
        bool: false,
        ref : {
            "Name" : "client",
            "Address" : nameToAddress("default")
        }
    }

    async function finalizeMethod() {
        let filledArgs = args.map((arg) => {
            const name = arg[0]
            const type = arg[1]
            return argValues[name] ? argValues[name] : defaultArgValues[type]
        })

        try {
            let result
            if(isConst) {
                result = await callConstMethod(caller, address, componentName, methodName, ...filledArgs)
            }
            else {
                if(isPayable) {
                    let balance = {}
                    balance[tokenName] = tokenAmount
                    result = await callPayableMethod(caller, address, componentName, methodName, balance, ...filledArgs)
                } else {
                    result = await callPayableMethod(caller, address, componentName, methodName, {}, ...filledArgs)
                }
            }
            setSuccess("Result: " + JSON.stringify(result))
            setError()
            await sleep(15000)
            setSuccess()
        } catch (e) {
            setError(e)
            setSuccess()
            await sleep(15000)
            setError()
        }
    }

    function setArgValue(argName, argValue) {
        let newValue = {}
        newValue[argName] = argValue
        setArgValues({...argValues, ...newValue})
        argValues[argName] = argValue
    }

    const argElements = args.map((arg) => {
        const name = arg[0]
        const type = arg[1]
        return <Argument key={name} name={name} type={type} setValue={(value) => setArgValue(name, value)}/>
    })

    const tokenArgs = <Box sx={{pt:1}}>
        <TextField size="small" value={tokenName} onChange={(event) => setTokenName(event.target.value)} placeholder={"Token Name"}/>
        <TextField size="small" type="number" value={tokenAmount} onChange={(event) => setTokenAmount(Number(event.target.value))}
                   placeholder={"Token Amount"} inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}/>
    </Box>

    return <Accordion elevation={5}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography sx={{width: '33%', flexShrink: 0}}>
                {methodName}
            </Typography>
        </AccordionSummary>
        {/*<Divider/>*/}
        <AccordionDetails>
            {argElements}
            {isPayable ? tokenArgs : null}
            <Box sx={{pt:1}}>
                <Button onClick={finalizeMethod} color="primary" variant="outlined">Call</Button>
            </Box>
            <Box sx={{pt:1}}>{alert} </Box>
        </AccordionDetails>
    </Accordion>

}